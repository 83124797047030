import { Today } from "../../helpers/Date";
import { SET_CHARGES, SET_DATE, SET_LOCATION, TOGGLE_CHARGES } from "../types";

const defaultState = {
  location: "DK1",
  showCharges: true,
  currentDate: Today(),
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case SET_CHARGES:
      return {
        ...state,
        showCharges: action.payload,
      };
    case TOGGLE_CHARGES:
      return {
        ...state,
        showCharges: !state.showCharges,
      };
    case SET_DATE:
      return {
        ...state,
        currentDate: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
