function InfoGraphicItem({ Icon, title }) {
  return (
    <div className="flex w-1/2 sm:w-auto items-center my-1 sm:my-4 px-6">
      <Icon />
      <div className={"ml-2"}>
        <p className={"text-gray-700"}>{title}</p>
      </div>
    </div>
  );
}

export default InfoGraphicItem;
