import StatComponent from "./StatComponent";
import { connect } from "react-redux";
import { addVAT, formattedPrice, getBarColor } from "../helpers/FormatItem";
import { Price } from "../helpers/Price";
import {
  CurrentHour,
  DateFormatted,
  Hour,
  NextTime,
  Time,
  TimeNow,
  Today,
} from "../helpers/Date";
import {
  getNetCompanyCharges,
  getNetCompanyCTime,
  setMaxPrice,
} from "../store/actions/livedata";

function Stats({
  className,
  items,
  hasItems,
  preferences,
  maxPrice,
  netCompany,
  getNetCompanyCharges,
}) {
  const netCompanyCTimeCharges = (index) => {
    if (!preferences.showCharges) return 0;

    return addVAT(getNetCompanyCTime(netCompany, index));
  };

  const getMostExpensive = () => {
    if (hasItems) {
      return items.reduce((acc, item, index) => {
        if (
          formattedPrice(
            item.price_dkk,
            preferences.showCharges,
            getNetCompanyCharges
          ) +
            netCompanyCTimeCharges(index) >
          acc
        ) {
          return (
            formattedPrice(
              item.price_dkk,
              preferences.showCharges,
              getNetCompanyCharges
            ) + netCompanyCTimeCharges(index)
          );
        }
        return acc;
      }, 0);
    }
    return "N/A";
  };

  const cheapestPrice = () => {
    if (hasItems) {
      return items.reduce((acc, item, index) => {
        if (
          formattedPrice(
            item.price_dkk,
            preferences.showCharges,
            getNetCompanyCharges
          ) +
            netCompanyCTimeCharges(index) <
          acc
        ) {
          return (
            formattedPrice(
              item.price_dkk,
              preferences.showCharges,
              getNetCompanyCharges
            ) + netCompanyCTimeCharges(index)
          );
        }
        return acc;
      }, 1000);
    }
    return "N/A";
  };

  const PriceNow = () => {
    if (hasItems) {
      const item = items.find((item) => Hour(item.hour_dk) === CurrentHour());
      return item
        ? formattedPrice(
            item.price_dkk,
            preferences.showCharges,
            getNetCompanyCharges
          ) + netCompanyCTimeCharges(items.indexOf(item))
        : "N/A";
    }
    return "N/A";
  };

  const getAveragePrice = (items) => {
    const total = items.reduce((acc, item, index) => {
      return (
        acc +
        formattedPrice(
          item.price_dkk,
          preferences.showCharges,
          getNetCompanyCharges
        ) +
        netCompanyCTimeCharges(index)
      );
    }, 0);

    return total / items.length;
  };

  const getTimeOfPrice = (price) => {
    const item = items.find(
      (item, index) =>
        formattedPrice(
          item.price_dkk,
          preferences.showCharges,
          getNetCompanyCharges
        ) +
          netCompanyCTimeCharges(index) ===
        price
    );
    return item
      ? "kl. " + Time(item.hour_dk) + " - " + NextTime(item.hour_dk)
      : "N/A";
  };

  const isToday = preferences.currentDate === Today();

  return (
    <div className={`flex justify-between flex-wrap -m-4 ${className}`}>
      <StatComponent
        title={isToday ? "Prisen lige nu" : "Samme tidspunkt"}
        time={"kl. " + TimeNow()}
        color={getBarColor(PriceNow())}
        price={Price(PriceNow())}
        className={"w-1/2 md:w-1/4 p-4"}
      />
      <StatComponent
        title={"Laveste pris"}
        time={getTimeOfPrice(cheapestPrice())}
        color={getBarColor(cheapestPrice())}
        price={Price(cheapestPrice())}
        className={"w-1/2 md:w-1/4 p-4"}
      />
      <StatComponent
        title={"Højeste pris"}
        time={getTimeOfPrice(getMostExpensive())}
        color={getBarColor(getMostExpensive())}
        price={Price(getMostExpensive())}
        className={"w-1/2 md:w-1/4 p-4"}
      />
      <StatComponent
        title={"Gennemsnit"}
        time={DateFormatted(preferences.currentDate)}
        color={getBarColor(getAveragePrice(items))}
        price={Price(getAveragePrice(items))}
        className={"w-1/2 md:w-1/4 p-4"}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  items: state.livedata.items,
  maxPrice: state.livedata.maxPrice,
  hasItems: state.livedata.items.length > 0,
  preferences: state.preferences,
  netCompany: state.livedata.netCompany,
  getNetCompanyCharges: getNetCompanyCharges(state.livedata.netCompany),
});

export default connect(mapStateToProps, { setMaxPrice })(Stats);
