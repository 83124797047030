import { CLEAR_ADDRESS, SET_ADDRESS, SET_ADDRESS_LOADING } from "../types";

export const setAddress = (address) => {
  return {
    type: SET_ADDRESS,
    payload: address,
  };
};

export const setLoading = (loading) => {
  return {
    type: SET_ADDRESS_LOADING,
    payload: loading,
  };
};

export const clearAddress = () => {
  return {
    type: CLEAR_ADDRESS,
  };
};
