import { useEffect, useState } from "react";
import ElberegnerService from "../services/ElberegnerService.ts";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { connect } from "react-redux";
import Spinner from "./icons/Spinner";
import { setNetCompany } from "../store/actions/livedata";

const ChooseNetcompany = ({
  netCompany,
  setNetCompany,
  addressIsSelected,
  showCharges,
}) => {
  const [fetching, setFetching] = useState(false);
  const [netcompanies, setNetcompanies] = useState([]); // eslint-disable-line no-unused-vars
  const [showList, setShowList] = useState(false); // eslint-disable-line no-unused-vars

  useEffect(() => {
    const fetchNetcompanies = async () => {
      if (fetching) return;
      await setFetching(true);
      await new ElberegnerService()
        .fetchNetCompanies()
        .then((res) => setNetcompanies(res))
        .finally(() => {
          console.log("done fetching", fetching);
          setFetching(false);
        });
    };

    if (netcompanies.length === 0 && !fetching) {
      fetchNetcompanies();
    }
  }, [netcompanies, fetching]);

  const toggleList = () => {
    setShowList(!showList);
  };

  if (!showCharges) return null;

  return (
    <div className={"flex items-center justify-center sm:justify-end relative"}>
      <p className={"text-base sm:text-sm text-gray-700 mr-2"}>
        Vælg netselskab:
      </p>
      {fetching && <Spinner />}
      {!fetching && (
        <div className={""}>
          <button
            disabled={addressIsSelected || !showCharges}
            className={
              "text-base sm:text-sm font-semibold text-black flex space-x-1 items-center disabled:opacity-50 transition-all"
            }
            onClick={toggleList}
          >
            <ChevronUpDownIcon className={"w-4 h-4"} stroke={2} />
            {netCompany?.pretty_name || netCompany?.name || "Ikke valgt"}
          </button>
          {showList && (
            <div
              className={
                "absolute top-8 max-h-[250px] overflow-y-scroll bg-white rounded shadow-xl right-0 z-20 max-w-lg w-full"
              }
            >
              <ul>
                {netcompanies.map((netcompany) => (
                  <li key={netcompany.id}>
                    <button
                      className={
                        "text-base py-1.5 px-2 border-b border-gray-300 sm:text-sm text-black flex w-full space-x-1 items-center hover:bg-green-600 hover:text-white transition-all"
                      }
                      onClick={() => {
                        setNetCompany(netcompany);
                        setShowList(false);
                      }}
                    >
                      {netcompany.pretty_name || netcompany.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    netCompany: state.livedata.netCompany,
    showCharges: state.preferences.showCharges,
    addressIsSelected: state.address.item !== null,
  };
};
export default connect(mapStateToProps, { setNetCompany })(ChooseNetcompany);
