import axios, { AxiosInstance } from "axios";
import { Tomorrow } from "../helpers/Date";

// make PriceResponse interface as an array of PriceRecord
export interface PriceResponse extends Array<PriceRecord> {}

export interface PriceRecord {
  id: number;
  hour_dk: string;
  hour_utc: string;
  price_zone: "DK1" | "DK2";
  price_dkk: number;
  price_eur: number;
  created_at: string;
  updated_at: string;
}

class ElberegnerService {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: "https://api.elberegner.dk/api",
      //withCredentials: true
    });
  }

  async fetchLivePrices(date: Date, priceZone: "DK1" | "DK2") {
    return await this.axios
      .get("/prices", {
        params: {
          date: date,
          area: priceZone,
        },
      })
      .then(({ data }) => {
        return data as PriceResponse;
      })
      .catch((error) => {
        return [];
      });
  }

  async getAddressSuggestions(address: string) {
    return await this.axios
      .get("/addresses/lookup/suggestions", {
        params: {
          search: address,
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        return [];
      });
  }

  async fetchNetCompanies() {
    return await this.axios
      .get("/netcompany")
      .then(({ data }) => {
        return data?.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async lookupNetCompany(address: string) {
    return await this.axios
      .get("/netcompany/powergrid", {
        params: {
          address: address,
        },
      })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async checkTomorrow(priceZone: "DK1" | "DK2") {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    return (await this.fetchLivePrices(Tomorrow(), priceZone)).length > 0;
  }
}

export default ElberegnerService;
