import {
  DateMinusDays,
  DateFormatted,
  DatePlusDays,
  Today,
  Tomorrow,
} from "../helpers/Date";
import { connect } from "react-redux";
import { setCurrentDate } from "../store/actions/preferences";

function DateSwitcher({ setCurrentDate, currentDate, tomorrowHasData }) {
  const increaseDate = () => {
    setCurrentDate(DatePlusDays(currentDate, 1));
  };

  const decreaseDate = () => {
    setCurrentDate(DateMinusDays(currentDate, 1));
  };

  const dateText = () => {
    switch (currentDate) {
      case Today():
        return "I dag";
      case DatePlusDays(Today(), 1):
        return "I morgen";
      case DateMinusDays(Today(), 1):
        return "I går";
      default:
        return "Dato";
    }
  };

  const isNextAvailable = () => {
    if (currentDate === Today()) {
      if (tomorrowHasData) {
        return false;
      }

      return true;
    } else if (currentDate === Tomorrow()) {
      return true;
    }

    return false;
  };

  const checkAvailability = isNextAvailable();

  return (
    <div className={"flex justify-between items-center min-w-[300px] -mx-4"}>
      <button
        onClick={decreaseDate}
        className={"px-4 text-gray-300 hover:text-black transition-colors"}
      >
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="18"
            cy="18"
            r="17.5"
            transform="rotate(180 18 18)"
            fill="#F9FAFB"
            stroke="currentColor"
          />
          <path
            d="M20 12.8419L15.4068 16.6116C14.8644 17.0567 14.8644 17.7852 15.4068 18.2304L20 22"
            stroke="black"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <div className={"text-center px-4"}>
        {dateText() ?? <p>{dateText()}</p>}
        <p className={"text-gray-500 text-sm"}>{DateFormatted(currentDate)}</p>
      </div>
      <button
        onClick={increaseDate}
        disabled={checkAvailability}
        className={
          "px-4 text-gray-300 hover:text-black transition-colors disabled:opacity-30 disabled:text-gray-200 focus:text-gray-200"
        }
      >
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="18"
            cy="18"
            r="17.5"
            fill="#F9FAFB"
            stroke="currentColor"
          />
          <path
            d="M16 22.1581L20.5932 18.3884C21.1356 17.9433 21.1356 17.2148 20.5932 16.7696L16 13"
            stroke="black"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentDate: state.preferences.currentDate,
    tomorrowHasData: state.livedata.tomorrowHasData,
  };
};

export default connect(mapStateToProps, { setCurrentDate })(DateSwitcher);
