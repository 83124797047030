import moment from "moment";
export function Today() {
  // return the date in format YYYY-MM-DD
  return moment().locale("dk").format("YYYY-MM-DD");
}

export function Tomorrow() {
  // return the date in format YYYY-MM-DD
  return moment().locale("dk").add(1, "days").format("YYYY-MM-DD");
}

export function DateMinusDays(date, days) {
  // return the date in format YYYY-MM-DD
  return moment(date).locale("dk").subtract(days, "days").format("YYYY-MM-DD");
}

export function DatePlusDays(date, days) {
  // return the date in format YYYY-MM-DD
  return moment(date).locale("dk").add(days, "days").format("YYYY-MM-DD");
}

export function DateSince(date) {
  // return the date in format YYYY-MM-DD
  return moment(date).zone(date).locale("da").fromNow();
}

export function DateFormatted(date) {
  // return the date in format DD-MM-YYYY
  return moment(date).locale("dk").format("DD MMMM YYYY");
}

export function TodayDate() {
  return moment().locale("dk").format("DD MMMM YYYY");
}

export function Date(date = null) {
  // return the date in format YYYY-MM-DD
  return moment(date).zone(date).format("YYYY-MM-DD");
}

export function Hour(date = null) {
  // copenhagen time
  return moment(date).zone(date).format("HH");
  // return the hour in format HH:mm
}

export function CurrentHour() {
  // copenhagen time
  return moment().zone("Europe/Copenhagen").format("HH");
}

export function Time(date = null) {
  // copenhagen time
  return moment(date).zone(date).locale("dk").format("HH:mm");
}

export function TimeNow() {
  // copenhagen time
  return moment().locale("dk").format("HH:mm");
}

export function NextTime(date = null) {
  // copenhagen time
  return moment(date).zone(date).locale("dk").add(1, "hours").format("HH:mm");
}
