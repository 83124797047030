import { CLEAR_ADDRESS, SET_ADDRESS, SET_ADDRESS_LOADING } from "../types";

const defaultState = {
  item: null,
  loading: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_ADDRESS:
      return {
        ...state,
        item: action.payload,
        loading: false,
      };
    case CLEAR_ADDRESS:
      return {
        ...state,
        item: null,
        loading: false,
      };
    case SET_ADDRESS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
}
