import { connect } from "react-redux";
import { toggleCharges } from "../store/actions/preferences";
import ReactTooltip from "react-tooltip";
import { FaInfoCircle } from "react-icons/fa";

function ToggleCharges({ showCharges, toggleCharges, className }) {
  const handleOnChange = () => {
    toggleCharges();
  };

  const checkedClasses = showCharges ? " bg-green-500 border-green-600" : "";

  return (
    <div
      className={
        "flex items-center justify-center sm:justify-end mb-2 sm:mb-0 " +
        className
      }
    >
      <label htmlFor="charges" className={"cursor-pointer flex items-center"}>
        <input
          id="charges"
          type="checkbox"
          className={"hidden"}
          onChange={handleOnChange}
          checked={showCharges}
        />
        <div
          className={
            "border border-gray-200 w-6 h-6 sm:w-4 sm:h-4 rounded-sm mr-2 flex items-center justify-center" +
            checkedClasses
          }
        >
          {showCharges && (
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.39644 6.86252L0.146441 3.81174C-0.0488135 3.62846 -0.0488135 3.33128 0.146441 3.14798L0.853531 2.48422C1.04879 2.30091 1.36539 2.30091 1.56064 2.48422L3.75 4.53935L8.43936 0.137464C8.63461 -0.0458212 8.95121 -0.0458212 9.14647 0.137464L9.85356 0.801228C10.0488 0.984513 10.0488 1.28169 9.85356 1.46499L4.10355 6.86254C3.90828 7.04582 3.5917 7.04582 3.39644 6.86252Z"
                fill="#F9FAFB"
              />
            </svg>
          )}
        </div>
        <div>
          <p className={"text-base sm:text-sm text-gray-700"}>
            Vis priser med afgifter
          </p>
        </div>
      </label>
      <div className={"shrink-1"}>
        <div
          className={
            "text-sm text-gray-500 w-full text-center sm:text-right ml-1"
          }
          data-tip={
            "Vis priser inkl. elafgift, transmissionsnettarif, systemtarif og omkostninger til netselskabet."
          }
        >
          <FaInfoCircle />
        </div>
        <ReactTooltip multiline={true} className={"max-w-[300px]"} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    showCharges: state.preferences.showCharges,
  };
};

export default connect(mapStateToProps, { toggleCharges })(ToggleCharges);
