function ChargesSkin() {
  return (
    <svg
      width="42"
      height="15"
      viewBox="0 0 42 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_229_1355"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="42"
        height="15"
      >
        <path
          d="M41.08 0L41.08 15L-4.6432e-05 15L-4.57764e-05 -1.79566e-06L41.08 0Z"
          fill="#04B465"
        />
      </mask>
      <g mask="url(#mask0_229_1355)">
        <line
          x1="53.0485"
          y1="17.7071"
          x2="34.0485"
          y2="-1.29289"
          stroke={"currentColor"}
          strokeWidth="2"
        />
        <line
          x1="44.0485"
          y1="17.7071"
          x2="25.0485"
          y2="-1.29289"
          stroke={"currentColor"}
          strokeWidth="2"
        />
        <line
          x1="35.0485"
          y1="17.7071"
          x2="16.0485"
          y2="-1.29289"
          stroke={"currentColor"}
          strokeWidth="2"
        />
        <line
          x1="26.0485"
          y1="17.7071"
          x2="7.04851"
          y2="-1.29289"
          stroke={"currentColor"}
          strokeWidth="2"
        />
        <line
          x1="17.0485"
          y1="17.7071"
          x2="-1.95149"
          y2="-1.29289"
          stroke={"currentColor"}
          strokeWidth="2"
        />
        <line
          x1="8.04851"
          y1="17.7071"
          x2="-10.9515"
          y2="-1.29289"
          stroke={"currentColor"}
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default ChargesSkin;
