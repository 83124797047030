import { CurrentHour, Hour } from "./Date";
import { config } from "../config";

export const barHeight = (price, maxPrice = 5) => {
  let height = price / maxPrice;
  return height * 100 + "%";
};

export const getBarColor = (price) => {
  if (parseFloat(price) < 2) {
    return "#039855";
  } else if (parseFloat(price) < 3.5) {
    return "#FEC84B";
  } else {
    return "#D92D20";
  }
};

export const isCurrentHour = (hour) => {
  return Hour(hour) === CurrentHour();
};

export const roundPrice = (price) => {
  return Math.ceil(price * 100) / 100;
  // round down or up to 2 decimals
};

export const formattedPrice = (
  price,
  charges: boolean,
  netCompanyCharges = 0
) => {
  let add = 0;

  if (charges) {
    add =
      config.elafgift +
      config.transmissionsnettarif +
      config.systemtariff +
      config.systemAbonnement +
      netCompanyCharges;
  }

  price = price / 1000 + add; // udregner pris pr. kg
  return addVAT(price);
};

export const totalCharges =
  config.elafgift + config.transmissionsnettarif + config.systemtariff;

export const addVAT = (price) => {
  return price * 1.25;
};
