const Credits = () => {
  // if hostname is not equal to elberegner.dk
  if (window.location.hostname === "elberegner.dk") {
    // return null
    return null;
  }

  const handleClick = (e) => {
    e.preventDefault();
    // open a new tab with the url
    window.open("https://elberegner.dk", "_blank");
  };

  return (
    <div
      className={
        "inline-flex items-center justify-center text-xs text-gray-400 mt-4"
      }
    >
      <div>
        Denne graf er udviklet og vedligeholdt af{" "}
        <a
          onClick={(e) => handleClick(e)}
          href={"https://elberegner.dk"}
          className={"text-green-600 hover:text-black"}
        >
          elberegner.dk
        </a>
        .
      </div>
    </div>
  );
};

export default Credits;
