function StatComponent({ className, title, price, color, time }) {
  return (
    <div className={className}>
      <div className={"flex flex-wrap "}>
        <h2 className={"text-gray-600 font-medium text-sm w-full"}>{title}</h2>
        <div className={"mt-2"}>
          <p className={"text-gray-600 text-2xl font-semibold w-full"}>
            {price}
          </p>
          <div className={"flex items-center"}>
            <div
              className={"w-3 h-3 rounded-full mr-2"}
              style={{ backgroundColor: color }}
            />
            <p className={"text-xs text-gray-500"}>{time}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

// default props
StatComponent.defaultProps = {
  title: "Dette er en overskrift",
  price: "0,00 kr",
  color: "#E5E7EB",
  time: "",
};

export default StatComponent;
