import { SET_DATE, SET_LOCATION, TOGGLE_CHARGES } from "../types";

export const setCurrentDate = (date) => ({
  type: SET_DATE,
  payload: date,
});

export const toggleCharges = () => ({
  type: TOGGLE_CHARGES,
});

export const changeLocation = (location) => ({
  type: SET_LOCATION,
  payload: location,
});
