import { combineReducers } from "redux";
import livedata from "./livedata";
import preferences from "./preferences";
import address from "./address";

export default combineReducers({
  livedata,
  preferences,
  address,
});
