import {
  SET_LIVE_DATA,
  SET_LIVE_DATA_LOADING,
  SET_MAX_PRICE,
  SET_NET_COMPANY,
  SET_TOMORROW_HAS_DATA,
} from "../types";

const defaultState = {
  items: [],
  loading: false,
  tomorrowHasData: false,
  netCompany: {},
  maxPrice: 5,
};

export default function livedata(state = defaultState, action) {
  switch (action.type) {
    case SET_LIVE_DATA:
      return {
        ...state,
        items: action.payload,
      };
    case SET_LIVE_DATA_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_TOMORROW_HAS_DATA:
      return {
        ...state,
        tomorrowHasData: action.payload,
      };
    case SET_MAX_PRICE:
      return {
        ...state,
        maxPrice: action.payload,
      };
    case SET_NET_COMPANY:
      return {
        ...state,
        netCompany: action.payload,
      };
    default:
      return state;
  }
}
