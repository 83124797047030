import { useCallback, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  setLiveData,
  liveDataIsLoading,
  tomorrowHasData,
  setMaxPrice,
  getNetCompanyCharges,
} from "../store/actions/livedata";
import ElberegnerService from "../services/ElberegnerService.ts";
import Chart from "./Chart";
import Stats from "./Stats";
import DateSwitcher from "./DateSwitcher";
import ToggleCharges from "./ToggleCharges";
import InfoGraphics from "./InfoGraphics";
import LocationSwitcher from "./LocationSwitcher";
import { Today } from "../helpers/Date";
import { formattedPrice } from "../helpers/FormatItem";
import AddressForm from "./AddressForm";
import ChooseNetcompany from "./ChooseNetcompany";
import Credits from "./Credits";

function LiveDataChart({
  items,
  loading,
  setLiveData,
  netCompany,
  liveDataIsLoading,
  preferences,
  getNetCompanyCharges,
  tomorrowHasData,
  setMaxPrice,
  maxPrice,
}) {
  const ref = useRef(0);
  const divHeight = useRef(0);

  useEffect(() => {
    detectHeight();

    // add event listener
    window.addEventListener("resize", detectHeight);

    return () => {
      // remove event listener
      window.removeEventListener("resize", detectHeight);
    };
  }, []);

  const detectHeight = () => {
    const height = ref.current.clientHeight;
    // get body element
    const elem = document.querySelector("body");
    const elemHeight = elem.clientHeight;

    console.log("height", height, "elemHeight", elemHeight);

    if (height !== divHeight.current) {
      divHeight.current = height;
      //console.log("height changed", height);
      window.parent.postMessage(
        {
          height: height,
          type: "resize-feed",
        },
        "*"
      );
      window.dispatchEvent(new CustomEvent("resize-feed", { detail: height }));
    }
  };

  const loadData = useCallback(async () => {
    if (!loading) {
      await liveDataIsLoading(true);

      await new ElberegnerService()
        .fetchLivePrices(preferences.currentDate, preferences.location)
        .then(async (data) => {
          // get most expensive hour in data
          let mostExpensiveHour = data.reduce((prev, current) =>
            formattedPrice(prev.price_dkk, true, getNetCompanyCharges) >
            formattedPrice(current.price_dkk, true, getNetCompanyCharges)
              ? prev
              : current
          );
          const mostExpensive = formattedPrice(
            mostExpensiveHour.price_dkk,
            preferences.showCharges,
            getNetCompanyCharges
          ).toFixed(0);
          if (mostExpensive > maxPrice) {
            await setMaxPrice(mostExpensive);
          }
          await setLiveData(data);
          if (preferences.currentDate === Today()) {
            new ElberegnerService()
              .checkTomorrow(preferences.location)
              .then(async (data) => {
                await tomorrowHasData(data);
              });
          }
        })
        .catch((error) => {})
        .finally(async () => {
          await liveDataIsLoading(false);
          detectHeight();
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences.currentDate, preferences.location, netCompany]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div ref={ref} id={"kkk"}>
      <AddressForm />
      <div className={"border border-gray-200 rounded p-6 mt-5"}>
        <div className="text-center -mt-11 mb-6">
          <LocationSwitcher
            className={"max-w-[400px] inline-block w-full mb-2"}
          />
        </div>
        <Stats className={"mb-2"} />
        <div
          className={"flex flex-wrap justify-between items-center mb-3 sm:mb-6"}
        >
          <div className={"hidden md:block w-1/4"} />
          <div className={"w-full sm:w-auto mb-6 sm:mb-0"}>
            <DateSwitcher />
          </div>
          <div className={"w-full sm:w-1/2 md:w-1/4 space-y-2"}>
            <ToggleCharges className={"w-full"} />
            <ChooseNetcompany className={"w-full"} />
          </div>
        </div>
        <Chart
          netCompanyCharges={getNetCompanyCharges}
          items={items}
          loading={loading}
        />
        <p className={"text-gray-500 mt-3 text-xs sm:text-center"}>
          <i>Priser vises i kr./kWh inkl. moms</i>
        </p>
        <InfoGraphics className={"mt-6"} />
        <Credits />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    preferences: state.preferences,
    netCompany: state.livedata.netCompany,
    items: state.livedata.items,
    maxPrice: state.livedata.maxPrice,
    loading: state.livedata.loading,
    getNetCompanyCharges: getNetCompanyCharges(state.livedata.netCompany),
  };
};

export default connect(mapStateToProps, {
  setLiveData,
  setMaxPrice,
  liveDataIsLoading,
  tomorrowHasData,
})(LiveDataChart);
