import { Price } from "../helpers/Price";
import { connect } from "react-redux";
import { config } from "../config";
import { Hour, Today } from "../helpers/Date";
import {
  formattedPrice,
  barHeight,
  getBarColor,
  isCurrentHour,
  addVAT,
} from "../helpers/FormatItem";
import {
  getNetCompanyCharges,
  getNetCompanyCTime,
} from "../store/actions/livedata";
import ReactTooltip from "react-tooltip";

function Bar({
  item,
  index,
  preferences,
  direction,
  maxPrice,
  netCompany,
  netCompanyCharges = 0,
}) {
  let price = formattedPrice(
    item.price_dkk,
    preferences.showCharges,
    netCompanyCharges
  );

  if (preferences.showCharges) {
    price += addVAT(getNetCompanyCTime(netCompany, index));
  }

  const color = getBarColor(price);
  const size = barHeight(price, maxPrice);

  const chargeSize = () => {
    const h =
      (addVAT(
        config.elafgift +
          config.transmissionsnettarif +
          config.systemtariff +
          config.systemAbonnement +
          netCompanyCharges +
          getNetCompanyCTime(netCompany, index)
      ) /
        price) *
      100;
    return h + "%";
  };

  const isToday = preferences.currentDate === Today();

  const currentStyles = () => {
    if (isToday && isCurrentHour(item.hour_dk)) {
      return {
        border: "1px dashed #000",
      };
    }

    return {};
  };

  let ToolTip =
    "Spotpris: " +
    Price(formattedPrice(item.price_dkk, false), false) +
    "<br />";

  if (preferences.showCharges) {
    ToolTip += "Elafgift: " + Price(addVAT(config.elafgift), true) + "<br />";
    ToolTip +=
      "Systemtariff: " + Price(addVAT(config.systemtariff), true) + "<br />";
    ToolTip +=
      "Transmissionsnettarif: " +
      Price(addVAT(config.transmissionsnettarif), false, 4) +
      "<br />";
    ToolTip +=
      "Systemabonnement: " +
      Price(addVAT(config.systemAbonnement), false, 4) +
      "<br />";
    if (getNetCompanyCharges(netCompany) > 0) {
      ToolTip +=
        "Nettariff C time: " +
        Price(addVAT(getNetCompanyCTime(netCompany, index))) +
        " kr. <br />";
      ToolTip +=
        "Netabonnement: " + Price(addVAT(netCompanyCharges)) + " <br />";
    }
    ToolTip += "<br />Total pris: " + Price(price);
  }

  if (direction === "horizontal") {
    return (
      <div
        className={"flex flex-wrap flex-col justify-center items-center px-0.5"}
      >
        <div
          className={
            "bg-gray-100 w-4 rounded-full grow relative transition-all hover:border-gray-700 border border-transparent overflow-hidden"
          }
          data-tip={ToolTip}
          style={{ ...currentStyles() }}
        >
          <ReactTooltip
            multiline={true}
            className={"max-w-[300px] text-left"}
          />
          <div
            className={"absolute bottom-0 inset-x-0 bg-green-600"}
            style={{ height: size, backgroundColor: color }}
          >
            {preferences.showCharges ? (
              <div
                className={
                  "absolute bottom-0 inset-x-0 bg-white/25 overflow-hidden"
                }
                style={{ height: chargeSize() }}
              >
                <svg
                  width="15"
                  height="42"
                  className={"h-full w-full"}
                  preserveAspectRatio="xMidYMin slice"
                  viewBox="0 0 15 42"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="17.7071"
                    y1="-11.2929"
                    x2="-1.29289"
                    y2="7.70711"
                    stroke={color}
                    strokeWidth="2"
                  />
                  <line
                    x1="17.7071"
                    y1="-2.29289"
                    x2="-1.29289"
                    y2="16.7071"
                    stroke={color}
                    strokeWidth="2"
                  />
                  <line
                    x1="17.7071"
                    y1="6.70711"
                    x2="-1.29289"
                    y2="25.7071"
                    stroke={color}
                    strokeWidth="2"
                  />
                  <line
                    x1="17.7071"
                    y1="15.7071"
                    x2="-1.29289"
                    y2="34.7071"
                    stroke={color}
                    strokeWidth="2"
                  />
                  <line
                    x1="17.7071"
                    y1="24.7071"
                    x2="-1.29289"
                    y2="43.7071"
                    stroke={color}
                    strokeWidth="2"
                  />
                  <line
                    x1="17.7071"
                    y1="33.7071"
                    x2="-1.29289"
                    y2="52.7071"
                    stroke={color}
                    strokeWidth="2"
                  />
                </svg>
              </div>
            ) : null}
          </div>
        </div>
        <div className={"shrink text-center mt-2"}>
          <p className={"text-[10px] w-[35px] text-gray-500"}>
            {"kl. " + Hour(item.hour_dk)}
          </p>
          <p className={"text-[10px] w-[35px] text-gray-700 font-bold"}>
            {Price(price)}
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"w-full my-0.5 px-0.5 flex items-center"}>
        <p className={"text-[11px] block text-gray-500 w-[55px]"}>
          {"kl. " + Hour(item.hour_dk)}
        </p>
        <p
          className={"text-[11px] block text-gray-700 font-bold w-[65px] mr-4"}
        >
          {Price(price)}
        </p>
        <div
          className={
            "bg-gray-100 h-2 w-full rounded-full grow relative overflow-hidden"
          }
          style={{ ...currentStyles() }}
        >
          <div
            className={"absolute left-0 inset-y-0 bg-green-600"}
            style={{ width: size, backgroundColor: color }}
          >
            {preferences.showCharges ? (
              <div
                className={
                  "absolute left-0 inset-y-0 bg-white/25 overflow-hidden"
                }
                style={{ width: chargeSize() }}
              >
                <svg
                  width="42"
                  height="15"
                  className={"h-full w-full"}
                  preserveAspectRatio="none"
                  viewBox="0 0 42 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="53.0486"
                    y1="17.7071"
                    x2="34.0486"
                    y2="-1.29289"
                    stroke={color}
                    strokeWidth="2"
                  />
                  <line
                    x1="44.0486"
                    y1="17.7071"
                    x2="25.0486"
                    y2="-1.29289"
                    stroke={color}
                    strokeWidth="2"
                  />
                  <line
                    x1="35.0486"
                    y1="17.7071"
                    x2="16.0486"
                    y2="-1.29289"
                    stroke={color}
                    strokeWidth="2"
                  />
                  <line
                    x1="26.0486"
                    y1="17.7071"
                    x2="7.04856"
                    y2="-1.29289"
                    stroke={color}
                    strokeWidth="2"
                  />
                  <line
                    x1="17.0486"
                    y1="17.7071"
                    x2="-1.95144"
                    y2="-1.29289"
                    stroke={color}
                    strokeWidth="2"
                  />
                  <line
                    x1="8.04855"
                    y1="17.7071"
                    x2="-10.9514"
                    y2="-1.29289"
                    stroke={color}
                    strokeWidth="2"
                  />
                </svg>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

// set default props
Bar.defaultProps = {
  direction: "horizontal",
};

const mapStateToProps = (state) => {
  return {
    preferences: state.preferences,
    maxPrice: state.livedata.maxPrice,
    netCompany: state.livedata.netCompany,
  };
};

export default connect(mapStateToProps)(Bar);
