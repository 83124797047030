import { connect } from "react-redux";
import { changeLocation } from "../store/actions/preferences";

function LocationSwitcher({
  className,
  location,
  changeLocation,
  addressIsSelected,
}) {
  const Switch = ({ title, active, onClick = () => {} }) => {
    const activeClass = active ? "bg-green-600 text-white" : "";
    // todo disable button when address is set
    return (
      <button
        disabled={addressIsSelected}
        onClick={onClick}
        className={`h-12 w-1/2 rounded-full p-4 flex items-center justify-center text-xs sm:text-sm font-medium ${activeClass} disabled:opacity-50 disabled:cursor-not-allowed`}
      >
        {title}
      </button>
    );
  };

  const handleClick = (location) => {
    console.log(location);
    changeLocation(location);
  };

  return (
    <div
      className={`border border-gray-200 bg-white inline-flex rounded-full overflow-hidden ${className}`}
    >
      <Switch
        title={"Vest for storebælt"}
        onClick={() => handleClick("DK1")}
        active={location === "DK1"}
      />
      <Switch
        title={"Øst for storebælt"}
        onClick={() => handleClick("DK2")}
        active={location === "DK2"}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    location: state.preferences.location,
    addressIsSelected: state.address.item !== null,
  };
};

export default connect(mapStateToProps, { changeLocation })(LocationSwitcher);
