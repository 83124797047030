const Button = ({ href, target, text }) => {
  return (
    <a
      href={href}
      target={target}
      className={
        "bg-orange-600 hover:bg-orange-700 transition-colors text-white py-3 font-medium px-6 rounded-full inline-block"
      }
    >
      {text}
    </a>
  );
};

export default Button;
