export const Price = (price, autoDigits = false, digitsNum = 2) => {
  let digits = digitsNum;
  if (autoDigits) {
    digits = price.toString().split(".")[1].length;
  }
  // format price to dkk
  const formatter = new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK",
    minimumFractionDigits: digits,
  });
  return formatter.format(price);
};
