import InfoGraphicItem from "./InfoGraphicItem";
import ChargesSkin from "./icons/ChargesSkin";

function InfoGraphics({ className }) {
  const ColorDisplay = (color) => {
    return <div className="w-10 h-4" style={{ backgroundColor: color }} />;
  };

  return (
    <div
      className={`flex flex-wrap sm:justify-center items-center -mx-6 ${className}`}
    >
      <InfoGraphicItem Icon={ChargesSkin} title={"Afgifter"} />
      <InfoGraphicItem
        Icon={() => ColorDisplay("#039855")}
        title={"Lav pris"}
      />
      <InfoGraphicItem
        Icon={() => ColorDisplay("#FEC84B")}
        title={"Mellem pris"}
      />
      <InfoGraphicItem
        Icon={() => ColorDisplay("#D92D20")}
        title={"Høj pris"}
      />
    </div>
  );
}

export default InfoGraphics;
