import LiveDataChart from "./components/LiveDataChart";
import moment from "moment";
function App() {
  moment.updateLocale("da", {
    months: [
      "Januar",
      "Februar",
      "Marts",
      "April",
      "Maj",
      "Juni",
      "Juli",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  });

  return <LiveDataChart />;
}

export default App;
